<template>
  <v-dialog v-model="GLOBAL_LOADING" persistent width="300">
    <v-card color="white" class="text-center py-3">
      <v-card-text>
        Here's a nice quote while you wait...
        <br>
        <br>
        <span class="font-weight-bold">
          "{{ quote }}"
        </span>
        <br>
        <br>
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GlobalLoader",
  data() {
    return {
      quote: "",
      quotes: [
        "I cannot teach anybody anything; I can only make them think.",
        "Tell me and I forget. Teach me and I remember. Involve me and I learn.",
        "Teaching is the greatest act of optimism.",
        "If you are planning for a year, sow rice; if you are planning for a decade, plant trees; if you are planning for a lifetime, educate people",
        "Education is not preparation for life; education is life itself.",
        "Teaching is the one profession that creates all other professions.",
        "Education breeds confidence. Confidence breeds hope. Hope breeds peace.",
        "If someone is going down the wrong road, he doesn't need motivation to speed him up. What he needs is education to turn him around.",
        "The dream begins, most of the time, with a teacher who believes in you, who tugs and pushes and leads you on to the next plateau, sometimes poking you with a sharp stick called truth.",
        "The art of teaching is the art of assisting discovery.",
        "Every child deserves a champion—an adult who will never give up on them, who understands the power of connection and insists that they become the best that they can possibly be.",
        "The mediocre teacher tells. The good teacher explains. The superior teacher demonstrates. The great teacher inspires.",
        "Better than a thousand days of diligent study is one day with a great teacher.",
        "You can teach a student a lesson for a day, but if you can teach him to learn by creating curiosity, he will continue the learning process as long as he lives.",
        "If kids come to us from strong, healthy, functioning families, it makes our job easier. If they do not come to us from strong, healthy, functioning families, it makes our job more important.",
        "A good teacher is like a candle—it consumes itself to light the way for others.",
        "What we want is to see the child in pursuit of knowledge, and not knowledge in pursuit of the child",
        "It takes a big heart to help shape little minds",
        "Education is the most powerful weapon which you can use to change the world.",
        "If you have to put someone on a pedastal, put teachers. They are society's heroes.",
        "When one teaches, two learn.",
        "In a completely rational society, the best of us would be teachers and the rest of us would have to settle for something else",
        "You cannot teach a crab to walk straight.",
        "There is no failure. Only feedback.",
        "Study without reflection is a waste of time; reflection without study is dangerous.",
      ]
    };
  },
  computed: {
    ...mapState({
      GLOBAL_LOADING: "globalLoading",
    }),
  },
  mounted: function () {
    this.quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
  },
  watch: {
      GLOBAL_LOADING: function(val) {
        if(val) {
          this.quote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
        }
      },
    }
};
</script>