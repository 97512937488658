import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import { doc, getDoc, getFirestore } from "firebase/firestore";
const db = getFirestore();

import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
const auth = getAuth();

Vue.use(VueRouter);

const routes = [
  {
    path: "/future/mis",
    name: "Home",
    component: HomeView,
    meta: { requiresAuth: false },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/terms.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/privacy.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/future/wellbeing",
    name: "Wellbeing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Wellbeing.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/",
    name: "TeacherToolKit",
    component: () => import(/* webpackChunkName: "about" */ "../views/TTK.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "TeacherToolBoxLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/verify",
    name: "Verify",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Verify.vue"),
    meta: { requiresAuth: false },
  },
  // {
  //   path: '/dashboard',
  //   name: 'TeacherToolKitDashboard',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TTKDash.vue'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: "/templates",
    name: "Templates",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Templates.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/history",
    name: "History",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/History.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/templates/:template",
    name: "Template",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Template.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/templates/:template/bulk",
    name: "Bulk",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Bulk.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/billing",
    name: "Billing",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Billing.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// Authentication
router.beforeEach(async (to, from, next) => {
  // if we are on dash and we are not logged in, redirect to login
  if (
    to.path !== "/login" &&
    to.matched.some((record) => record.meta.requiresAuth)
  ) {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        const userData = userSnap.data();
        if (!userData) {
          // they tried to go to another page without filling in extra data
          // sign them out then redirect to login
          signOut(auth)
            .then(() => {
              // Sign-out successful.
              this.$router.push("/login");
            })
            .catch((error) => {
              console.log(error);
            });
          next("/login");
        } else {
          next();
        }
      } else {
        next("/login");
      }
    });
  } else if (to.path === "/login") {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // if user check if extra data has been filled in
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        const userData = userSnap.data();
        if (userData) {
          // if there is data they shouldn't be here
          console.log(from);
          console.log(from.path);
          console.log(userData);
          next("/templates");
        } else {
          // they must have just logged in with Google
          // and have been redirected back
          next();
        }
      } else {
        // if no user let them login
        next();
      }
    });
  } else {
    next();
  }
  // if (to.matched.some(record => record.meta.darkTheme)) {
  //   vuetify.preset.theme.dark = true
  //   next()
  // } else {
  //   vuetify.preset.theme.dark = false
  //   console.log(vuetify.preset.theme)
  //   next()
  // }
  // if (to.matched.some(record => record.meta.authRequired)) {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       if(!['dev.fitrahcentre@gmail.com', 'raqi.fitrahcentre@gmail.com', 'main.fitrahcentre@gmail.com'].includes(user.email)) {
  //         store.dispatch('logout')
  //         store.commit('updateGlobalAlert', 'e')
  //         return
  //       }
  //       if(to.path === '/analytics' && user.email === 'raqi.fitrahcentre@gmail.com') {
  //         store.commit('updateUser', user)
  //         next('/');
  //       }
  //       store.commit('updateUser', user)
  //       next();
  //     } else {
  //       let redi = to.path === '/' ? undefined : { redirect: to.path }
  //       next({
  //         path: '/login',
  //         query: redi
  //       });
  //     }
  //   })
  // } else {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       store.commit('updateUser', user)
  //       if(to.query.redirect) {
  //         next(to.query.redirect)
  //       } else {
  //         next('/');
  //       }
  //     } else {
  //       next();
  //     }
  //   })
  // }
});

export default router;
