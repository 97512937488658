import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsPEE--xcfHgGsu9V9NzuNFgZ3ewhUg6Q",
  authDomain: "edutopia-web-2022.firebaseapp.com",
  projectId: "edutopia-web-2022",
  storageBucket: "edutopia-web-2022.appspot.com",
  messagingSenderId: "347736314406",
  appId: "1:347736314406:web:e13f4942f313e2e4850ce7",
  measurementId: "G-VW5TRHQ798"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});


let functions;
if (location.hostname === 'localhost') {
  console.log('local dev activated')
  functions = getFunctions(app, "europe-west2");
  connectAuthEmulator(auth, 'http://localhost:9099',{ disableWarnings: true });
  connectFirestoreEmulator(db, 'localhost', 8004);
  connectFunctionsEmulator(functions, 'localhost', 5001);
} else {
  functions = getFunctions(app, "europe-west2");
}
// functions = getFunctions(app, "europe-west2");
export { functions, payments };