import Vue from "vue";
import Vuex from "vuex";
import {
  getFirestore,
  query,
  onSnapshot,
  orderBy,
  doc,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { getCurrentUserSubscriptions } from "@stripe/firestore-stripe-payments";
import { payments } from "@/plugins/firebase";

const db = getFirestore();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    plans: [
      { name: "Basic", stripeId: "price_1MkphkBGRdIpmAOUUvVUFfpO", credits: 3000, price: 3.99 },
      { name: "Educator", stripeId: "price_1MkphkBGRdIpmAOU2IaWCXaD", credits: 10000, price: 7.99 },
      { name: "Pro", stripeId: "price_1MkphkBGRdIpmAOUQkECcT5d", credits: 20000, price: 14.99 },
      { name: "Super", stripeId: "price_1MkphkBGRdIpmAOUqRQQhOug", credits: 50000, price: 39.99 },
    ],
    userPlan: {
      active: false,
      trial: false,
      // if false then they have a free trial or they're using their bonus credits or nothing.
      // if nothing we'll show a dialog box to say that they should subscribe to something or use referral code.
      info: {},
      subscriptionObject: {}, 
    },
    userData: {},
    userHistory: [],
    products: [],
    showGlobalAlert: false,
    globalAlertType: "s",
    globalLoading: false,
    // types: singleText, multiText, singleSelect, comboBox, number,
    templates: [
      {
        name: "Student Report Helper",
        tags: ["Student"],
        desc: "Quickly create tailor-made reports for your students.",
        bulk: true,
        icon: "mdi-file-document-multiple-outline",
        needPaidPlan: false,
        fields: [
          {
            type: "singleSelect",
            id: "reportLength",
            name: "Report Length",
            hideInBulkMode: true,
            opts: ["Short", "Medium", "Long"],
            required: true,
            full: true,
            hint: "Short: (~100 words), Medium: (~200 words), Long: (~300 words)",
            persistentHint: true,
          },
          {
            type: "comboBox",
            id: "subject",
            hideInBulkMode: true,
            name: "Subject",
            maxLength: 50,
            required: true,
            placeholder: "Computer Science",
            opts: [
              "Arabic",
              "Art and Design",
              "Biology",
              "Business Studies",
              "Chemistry",
              "Computer Science",
              "Design and Technology",
              "Double Science",
              "Drama",
              "Economics",
              "English Language",
              "English Literature",
              "Food Technology",
              "French",
              "Further Mathematics",
              "Geography",
              "Geology",
              "German",
              "History",
              "Japanese",
              "Mandarin",
              "Mathematics",
              "Media Studies",
              "Physical Education",
              "Physics",
              "Psychology",
              "Religious Studies",
              "Science",
              "Sociology",
              "Spanish",
              "Statistics",
            ],
          },
          {
            type: "singleText",
            id: "studentName",
            name: "Student Name",
            maxLength: 50,
            required: true,
          },
          {
            type: "comboBox",
            id: "gender",
            name: "Gender",
            opts: ["Male", "Female"],
            required: true,
            maxLength: 20,
          },
          {
            type: "singleSelect",
            id: "effort",
            name: "Effort",
            opts: ["Excellent", "Good", "Average", "Poor", "Very Poor"],
            required: true,
          },
          {
            type: "singleText",
            id: "currGrade",
            name: "Current Grade",
            required: false,
            placeholder: "E.g. B",
          },
          {
            type: "singleText",
            id: "targGrade",
            name: "Target Grade",
            required: false,
            placeholder: "E.g. A",
          },
          {
            type: "singleSelect",
            id: "tone",
            name: "Tone",
            opts: [
              "Impressed",
              "Happy",
              "Neutral",
              "Encouraging",
              "Disappointed",
            ],
            required: true,
            full: true,
          },
          {
            type: "multiText",
            id: "unique",
            name: "Uniqueness",
            placeholder: `E.g. Works hard & focusses well in lessons.
Needs to work on punctuality.
Needs to revise topics a, b & c before the next test.`,
            required: true,
            minLength: 50,
            maxLength: 700,
            full: true,
          },
          {
            type: "multiText",
            id: "keywords",
            name: "Keywords",
            hint: "Highly recommended for longer reports",
            placeholder:
              "E.g. Determined, respectful, talkative.",
            maxLength: 300,
            required: false,
            full: true,
          },
          // {
          //   type: "multiText",
          //   id: "template",
          //   name: "Template",
          //   placeholder:
          //     "You can paste an example report that you'd like our AI to follow. Don't worry, our AI is smart enough to replace the key parts.",
          //   maxLength: 2000,
          //   required: false,
          //   full: true,
          //   advanced: true,
          // },
        ],
      },
      {
        name: "Summarise Text",
        tags: [""],
        desc: "Summarise any text into a given number of points.",
        bulk: false,
        icon: "mdi-text-short",
        needPaidPlan: false,
        fields: [
          {
            type: "multiText",
            id: "textToSummarise",
            name: "Text",
            placeholder: "Why not try part of an education research paper?",
            maxLength: 8000,
            minLength: 0,
            required: true,
            full: true,
          },
          {
            type: "singleSelect",
            id: "numberOfPoints",
            name: "Number of points to summarise to",
            opts: [3, 5, 10],
            required: true,
            full: true,
          },
        ],
      },
      {
        name: "Rephrase Concept",
        tags: [""],
        desc: "Rephrase any concept to make it easier for your students to understand.",
        bulk: false,
        icon: "mdi-backup-restore",
        needPaidPlan: false,
        fields: [
          {
            type: "multiText",
            id: "textToRephrase",
            name: "Text",
            placeholder: "Wave–particle duality is the concept in quantum mechanics that every particle or quantum entity may be described as either a particle or a wave.",
            maxLength: 5000,
            minLength: 0,
            required: true,
            full: true,
          },
          {
            type: "singleText",
            id: "instructions",
            name: "Instructions",
            placeholder:
              "'Simplify it', 'Explain it using an example', 'Make a story out of it' etc.",
            maxLength: 200,
            minLength: 0,
            required: true,
            full: true,
          },
        ],
      },
      {
        name: "Generate Parent Email",
        tags: [""],
        desc: "Quickly generate a personalised email to send to a parent.",
        bulk: false,
        icon: "mdi-email-edit-outline",
        needPaidPlan: false,
        fields: [
          {
            type: "singleText",
            id: "to",
            name: "To",
            placeholder: "Mr Smith",
            maxLength: 20,
            required: false,
          },
          {
            type: "singleSelect",
            id: "tone",
            name: "Tone",
            opts: [
              "Formal",
              "Funny",
              "Cheeky",
              "Celebratory",
              "Excited",
              "Passionate",
              "Motivational",
              "Impressed",
              "Encouraging",
              "Happy",
              "Neutral",
              "Serious",
              "Disappointed",
              "Angry",
            ],
            required: true,
          },
          {
            type: "multiText",
            id: "mainPoints",
            name: "Main points",
            placeholder: `School trip to the Zoo.
Need to bring a packed lunch.
Might rain.
Include mail merge fields to personalise the email.`,
            maxLength: 2000,
            required: true,
            minLength: 0,
            full: true,
          },
          {
            type: "multiText",
            id: "keywords",
            name: "Keywords",
            placeholder:
              "Important, urgent, detrimental.",
            maxLength: 300,
            required: false,
            full: true,
          },
        ],
      },
      {
        name: "Generate Speech Outline",
        tags: [""],
        desc: "Need to deliver an important assembly? This template will help you create a powerful speech outline.",
        bulk: false,
        icon: "mdi-account-group",
        oneOutputOnly : true,
        needPaidPlan: false,
        fields: [
          {
            type: "singleText",
            id: "speechTopic",
            name: "Topic",
            hint: "What is it mainly about?",
            placeholder: "E.g. The Importance of Maths",
            maxLength: 50,
            required: true,
            full: true,
          },
          {
            type: "singleSelect",
            id: "tone",
            name: "Tone",
            opts: [
              "Excited",
              "Passionate",
              "Motivational",
              "Impressed",
              "Encouraging",
              "Happy",
              "Neutral",
              "Serious",
              "Disappointed",
              "Angry",
            ],
            required: false,
            full: true,
          },
          {
            type: "multiText",
            id: "mainPoints",
            name: "Key points",
            placeholder:
              "You can write any key points that you'd like the speech outline to include. E.g. 'The importance of maths in the real world'",
            maxLength: 2000,
            required: false,
            full: true,
          },
          {
            type: "multiText",
            id: "keywords",
            name: "Keywords",
            placeholder:
              "You can add some keywords that our AI will try to include.",
            maxLength: 300,
            required: false,
            full: true,
          },
        ],
      },
      {
        name: "Generate Cover Letter",
        tags: [""],
        desc: "About to apply for a new teaching role? This template will help you create the perfect cover letter.",
        bulk: false,
        icon: "mdi-file-document-edit-outline",
        needPaidPlan: false,
        fields: [
          {
            type: "singleText",
            id: "jobTitle",
            name: "Job Title",
            placeholder: "Head of Physics",
            maxLength: 50,
            required: true,
            full: true,
          },
          {
            type: "multiText",
            id: "educationalBackground",
            name: "Educational Background",
            placeholder: "Msc Physics from Oxford University.",
            maxLength: 500,
            minLength: 0,
            required: true,
            full: true,
          },
          {
            type: "multiText",
            id: "relaventExperience",
            name: "Relavent Experience",
            placeholder: `Three years teaching experience.
Excellent results.
`,
            maxLength: 500,
            required: true,
            minLength: 0,
            full: true,
          },
          {
            type: "multiText",
            id: "keywords",
            name: "Keywords",
            placeholder:
              "Driven, passionate, motivated, hardworking, etc.",
            maxLength: 300,
            required: false,
            full: true,
          },
        ],
      },
      // {
      //   name: 'Essay Analyser',
      //   tags: ["Student"],
      //   desc: "A template to help you write a report on a student.",
      //   bulk: true,
      //   icon: "mdi-file-document-edit-outline",
      //   needPaidPlan: false,
      //   fields: [
      //     {
      //       type: "comboBox",
      //       id: "subject",
      //       name: "Subject",
      //       maxLength: 50,
      //       required: true,
      //       full: true,
      //       placeholder: "Economics",
      //       opts: [
      //         "Arabic",
      //         "Art and Design",
      //         "Biology",
      //         "Business Studies",
      //         "Chemistry",
      //         "Computer Science",
      //         "Design and Technology",
      //         "Double Science",
      //         "Drama",
      //         "Economics",
      //         "English Language",
      //         "English Literature",
      //         "Food Technology",
      //         "French",
      //         "Further Mathematics",
      //         "Geography",
      //         "Geology",
      //         "German",
      //         "History",
      //         "Japanese",
      //         "Mandarin",
      //         "Mathematics",
      //         "Media Studies",
      //         "Physical Education",
      //         "Physics",
      //         "Psychology",
      //         "Religious Studies",
      //         "Science",
      //         "Sociology",
      //         "Spanish",
      //         "Statistics",
      //       ],
      //     },
      //     {
      //       type: "multiText",
      //       id: "essay",
      //       name: "Essay",
      //       placeholder: "Paste the essay here",
      //       maxLength: 5000,
      //       minLength: 0,
      //       required: true,
      //       full: true,
      //     },
      //     {
      //       type: "singleSelect",
      //       id: "numberOfPoints",
      //       name: "Number of points to summarise to",
      //       hint: "To give you a quick overview",
      //       persistentHint: true,
      //       opts: [3, 5, 10],
      //       required: true,
      //       full: true,
      //     },
      //     {
      //       type: "multiText",
      //       id: "ao1",
      //       name: "Assessment Objective 1",
      //       maxLength: 300,
      //       minLength: 0,
      //       required: true,
      //       full: true,
      //     },
      //     {
      //       type: "multiText",
      //       id: "ao2",
      //       name: "Assessment Objective 2",
      //       maxLength: 300,
      //       required: false,
      //       full: true,
      //     },
      //     {
      //       type: "multiText",
      //       id: "ao3",
      //       name: "Assessment Objective 3",
      //       maxLength: 300,
      //       required: false,
      //       full: true,
      //     },
      //   ]
      // },
      {
        name: "Generate CPD Slides",
        tags: [""],
        desc: "No time to make that CPD presentation? This template will help you generate CPD slides that reference academic papers.",
        bulk: false,
        icon: "mdi-account-school",
        oneOutputOnly: true,
        needPaidPlan: false,
        fields: [
          {
            type: "singleText",
            id: "cpdSlidesTopic",
            name: "CPD Topic",
            hint: "What is it mainly about?",
            placeholder: "Assessment for learning",
            maxLength: 50,
            required: true,
            full: true,
          },
          {
            type: "multiText",
            id: "cpdSlidesMainPoints",
            name: "Key points",
            placeholder: `SEN students.
Use of technology.`,
            maxLength: 2000,
            required: false,
            full: true,
          },
          {
            type: "multiText",
            id: "cpdSlidesKeywords",
            name: "Keywords",
            placeholder:
              "Scaffolding, differentiation.",
            maxLength: 300,
            required: false,
            full: true,
          },
          {
            type: "singleSelect",
            id: "cpdSlidesLength",
            name: "Number of slides",
            opts: [1,2,3],
            required: true,
            full: true,
            hint: "Each slide is around 200 words.",
            persistentHint: true,
          },
        ],
      },
    ],
    currentBulkCompletedNumber: 0,
    batchToDownload: null,
    batchResultsToDownload: [],
  },
  mutations: {
    updateUser(state, newVal) {
      state.user = newVal;
    },
    updateUserData(state, newVal) {
      state.userData = newVal;
    },
    updateGlobalLoading(state, newVal) {
      state.globalLoading = newVal;
    },
  },
  actions: {
    async getCurrentUserPlan({ state }) {
      const userSubscriptions = await getCurrentUserSubscriptions(payments);
      let activeSub = userSubscriptions.find((x) => x.status === "active");
      if (activeSub) {
        state.userPlan.active = true;
        const priceId = activeSub.price;
        const userPlan = state.plans.find((x) => x.stripeId === priceId);
        state.userPlan.info = userPlan
        state.userPlan.subscriptionObject = activeSub;
      } else {
        // means they are new users and haven't filled in info yet
        if(!state.userData.createdAt) return

        state.userPlan.active = false;
        // add 7 days to date the account was created
        const futureDate = new Date(state.userData.createdAt.seconds * 1000)
        futureDate.setDate(futureDate.getDate() + 7)
        const currentDate = new Date()
        if (currentDate < futureDate) {
          // Still in free trial
          state.userPlan.trial = true
          state.userPlan.info.credits = 1000
        } else {
          // Free trial is over.
          // Set trial to false and reset credits.
          state.userPlan.trial = false
          await setDoc(doc(db, "users", state.user.uid), {credits: 0}, {merge: true});

        }
      }
    },
    getUserData({ state }) {
      if(!state.user) return;
      onSnapshot(doc(db, "users", state.user.uid), (doc) => {
        state.userData = doc.data();
      });
    },
    getNumberCompletedReports({ commit, state }, bulkBatchId) {
      onSnapshot(
        doc(db, "users", state.user.uid, "history", bulkBatchId),
        (doc) => {
          state.currentBulkCompletedNumber = doc.data().completedNumber;
        }
      );
    },
    async getUserHistory({ commit, state }) {
      const q = query(
        collection(db, "users", state.user.uid, "history"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(q);
      state.userHistory = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        state.userHistory.push({ id: doc.id, ...doc.data() });
      });
    },
    async getBatchInfo({ commit, state }, bulkBatchId) {
      onSnapshot(
        doc(db, "users", state.user.uid, "history", bulkBatchId),
        (doc) => {
          state.batchToDownload = doc.data();
        }
      );
    },
    async getBatchResults({ commit, state }, bulkBatchId) {
      const q = query(
        collection(
          db,
          "users",
          state.user.uid,
          "history",
          bulkBatchId,
          "results"
        ),
        orderBy("orderIndex", "asc")
      );
      const querySnapshot = await getDocs(q);
      state.batchResultsToDownload = [];
      querySnapshot.forEach((doc) => {
        state.batchResultsToDownload.push({ id: doc.id, ...doc.data() });
      });
    },
  },
});
