<template>
  <div>
    <v-container class="primary my-0 py-0" fluid>
    <!-- Hero -->
    <v-row class="hero">
      <Particles
        class="particles-cont"
      />

      <v-col class="px-12 pt-12 mt-12" cols="12">
        <v-row>
          <v-col cols="12" class="mt-12">
            <div class="text-h4 text-md-h2">
              <span style="font-weight: 700"
                >The AI-powered school MIS you've <i>always</i> wanted.
              </span>
            </div>
          </v-col>
          <v-col cols="12" class="text-body-3 text-md-h5 mt-6">
            <div>
              Aren't you done with rubbish school management systems that are old, slow, and
              clunky?
              <br class="hidden-sm-and-down">
              We're making an intelligent one that is:
              <br class="hidden-md-and-up">
              <vue-typer
                :text="[
                  'simple but powerful.',
                  'delightful to use.',
                  'powered by AI.',
                  'blazing fast.',
                ]"
                :repeat="Infinity"
                :shuffle="false"
                initial-action="typing"
                :pre-type-delay="70"
                :type-delay="70"
                :pre-erase-delay="1000"
                :erase-delay="30"
                erase-style="backspace"
                :erase-on-complete="false"
                caret-animation="blink"
              ></vue-typer>
            </div>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-btn @click="$router.push('/toolbox')" class="primary--text font-weight-black" rounded  color="secondary">Teacher Toolbox</v-btn>
            <v-btn @click="$router.push('/wellbeing')" class="ml-2 primary--text font-weight-black" rounded  color="#ff2020">Wellbeing Monitor</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="px-0">
        <!-- vimg -->
        <v-img
          alt="wave pattern"
          src="../../public/wave.svg"
          width="100%"
          height="250px"
        />
      </v-col>
    </v-row>
  </v-container>
  <v-container class="white my-0 py-0" fluid>
    <v-row class="px-md-12">
      <v-col cols="12" class="mb-6">
        <div class="text-h3 text-center primary--text">
          <span class="logo-font">Everything you need</span>
          <br>
          <v-chip
            class="mt-4"
            color="primary"
            label
            small
            text-color="white"
          >
            <v-icon small left> mdi-clock </v-icon>
            Coming soon...
          </v-chip>
        </div>
      </v-col>
      <v-col v-for="b in benefits" :key="b.title" cols="12" :md="b.full ? '12':'4'">
        <v-card class="white--text primary text-center pa-6" outlined shaped>
          <!-- vuetify icon -->
          <v-icon class="display-1 mb-4 white--text" size="60px">
            mdi-{{ b.icon }}
          </v-icon>
          <br />
          <span class="text-h5 font-weight-black">
            {{ b.title }}
          </span>
          <br /><br />
          <span class="text-body-1 font-weight-bold">
            {{ b.body }}
          </span>
        </v-card>
      </v-col>
      <v-col cols="12" class="my-12">
        <v-divider class="primary"></v-divider>
      </v-col>
      <v-col cols="12" class="mb-12">
        <div class="text-h3 text-center primary--text">
          <span class="logo-font">What you can try now!</span>
        </div>
      </v-col>
      <v-col cols="12" class="mb-12 px-12 text-center">
        <v-row>
          <v-col cols="12" md="2" offset-md="4">
            <v-btn @click="$router.push('/toolbox')" class="primary--text font-weight-black" x-large color="secondary">Teacher Toolbox</v-btn>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn @click="$router.push('/wellbeing')" class="primary--text font-weight-black" x-large color="#ff2020">Wellbeing Monitor</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" class="my-12">
        <v-divider class="primary"></v-divider>
      </v-col>
      <v-col cols="12" md="6" class="mb-12">
        <div class="text-h3 text-center primary--text">
          <span class="logo-font">Be the first to first out when it's ready</span>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="mb-12 px-12">
        <v-text-field
        label="Email"
        outlined
        color="primary"
        ></v-text-field>
        <v-btn block rounded color="primary">Sign up</v-btn>
    </v-col> -->
    </v-row>
  </v-container>
  <v-container fluid class="primary mt-10 py-0">
    <v-row class="pa-0">
      <v-col cols="12" class="pa-0">
        <v-img
          style="transform: rotate(180deg);"
          alt="wave pattern"
          src="../../public/wave.svg"
          width="100%"
          height="250px"
        />
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>

<script>
import Particles from "@/components/Particles.vue";

export default {
  name: "Home",
  data: () => ({
    countDownKvs: [
      { full: "days", short: "DAYS", size: 10 },
      { full: "hours", short: "HRS", size: 24 },
      { full: "minutes", short: "MIN", size: 60 },
      { full: "seconds", short: "SEC", size: 60 },
    ],
    showRest: false,
    benefits: [
      {
        icon: "brain",
        title: "AI Powered.",
        body: "Your productivity enhanced. Tenfold."
      },
      {
        icon: "flash",
        title: "Blazing Fast.",
        body: "No more staring at a never-ending loading screen. Edutopia will be blazing fast.",
      },
      {
        icon: "cards-heart",
        title: "Super Easy to Use.",
        body: "A beautiful & intuitive interface that you'll love.",
      },
      {
        icon: "chart-box",
        title: "Powerful Analytics.",
        body: "No need for manual data analysis anymore. Know anything about your school with the click of a button.",
      },
      {
        icon: "list-box",
        title: "Attendance Management.",
        body: "Keep track of your students' attendance with ease.",
      },
      {
        icon: "timetable",
        title: "Flexible Timetabling.",
        body: "Easily create your school timetables with our flexible timetabling system.",
      },      
      {
        icon: "bottle-tonic-plus",
        title: "Track well-being.",
        body: "Track your students' and staff well-being with our well-being tracker.",
      },
      {
        icon: "account-multiple",
        title: "Cover Management.",
        body: "No more fiddling around figuring out who has a free. Seemless and automated cover management.",
      },
      {
        icon: "alert",
        title: "Safeguarding.",
        body: "Easily collaborate to keep your most vulnerable safe and secure.",
      },
      {
        icon: "folder-star",
        title: "SEN.",
        body: "Manage your school's SEN data with our SEN management system.",
      },
      {
        icon: "help-circle",
        title: "UCAS Support.",
        body: "Powerful AI-powered tools to help and guide students.",
      },
      {
        icon: "account-multiple-plus",
        title: "Enrollment.",
        body: "Efficiently enroll new students. No need to keep exporting and importing data.",
      },
      {
        full: true,
        icon: "security",
        title: "Maximum Security.",
        body: "Your data is safe with us. We use the latest security technologies to keep your data secure.",
      },
    ],
  }),
  computed: {
    nextWebinar() {
      let now = new Date().getTime();
      // // Our numbers from above, times 5 for 5 days
      // let fiveDaysFromNow = now + (1000 * 60 * 60 * 24 * 5);
      let nextWeb = new Date("2022-10-20");
      return nextWeb - now;
    },
  },
  methods: {},
  components: {
    Particles,
  },
};
</script>

<style>
.vue-typer span {
  color: #fff !important;
  font-weight: 700;
}

.vue-typer .custom.caret {
  width: 5px;
  background-color: #fff;
}
.particles-cont {
  height: 80vh;
  width: 100%;
  position: absolute;
  z-index: 0;
}

.blend {
  background: #1e3c72;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2a5298, #1e3c72);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2a5298, #1e3c72); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

</style>
