

<template>
  <div
    :class="{'particles-js': true,}"
    :id="id"
  ></div>
</template>
  <script>
    /* eslint-disable */
    export default {
      name: 'vue-particles',
      data: function () {
        return {
          id: 'particles-instance-' + Math.floor(Math.random() * 5000),
        }
      },
      props: {
        pColour: {
          type: String,
          default: '#FFF'
        },
      },
      mounted () {
        // import particle.js only on client-side
        require('particles.js')
        this.$nextTick(() => {
          setTimeout(() => {
            this.initParticleJS()
          }, 100)
        })
      },
      methods: {
        initParticleJS () {
          particlesJS(this.id, {
            "particles": {
                "number": {
                "value": 150,
                "density": {
                    "enable": true,
                    "value_area": 800
                }
                },
                "color": {
                "value": this.pColour
                },
                "shape": {
                "type": "circle",
                "stroke": {
                    "width": 0,
                    "color": "#000000"
                },
                "polygon": {
                    "nb_sides": 5
                },
                "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                }
                },
                "opacity": {
                "value": 1,
                "random": true,
                "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.3,
                    "sync": false
                }
                },
                "size": {
                "value": 2,
                "random": true,
                "anim": {
                    "enable": true,
                    "speed": 1,
                    "size_min": 0.1,
                    "sync": false
                }
                },
                "line_linked": {
                "enable": false,
                "distance": 30,
                "color": "#9832ed",
                "opacity": 1,
                "width": 0.5
                },
                "move": {
                "enable": true,
                "speed": 1,
                "direction": "top",
                "random": false,
                "straight": true,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                    "enable": true,
                    "rotateX": 600,
                    "rotateY": 1200
                }
                }
            },
            "interactivity": {
                "detect_on": "canvas",
                "events": {
                "onhover": {
                    "enable": false,
                    "mode": "repulse"
                },
                "onclick": {
                    "enable": false,
                    "mode": "push"
                },
                "resize": true
                },
                "modes": {
                "grab": {
                    "distance": 400,
                    "line_linked": {
                    "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 3
                },
                "repulse": {
                    "distance": 500,
                    "duration": 0.4
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
                }
            },
            "retina_detect": true
            });
        }
  
      }
    }
    /* eslint-disable */
  </script>

<style>
    .slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

</style>