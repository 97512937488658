<template>
  <v-app>
    <SideNav v-if="showOnRequiresAuth" :temp="showTempSideNav" />
    <v-main>
      <router-view></router-view>
      <global-loader />
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import GlobalLoader from "./components/GlobalLoader.vue";
import SideNav from "./components/SideNav.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();
export default {
  components: { GlobalLoader, SideNav },
  name: "App",

  data: () => ({
    //
  }),
  methods: {
    ...mapMutations({
      UPDATE_USER: "updateUser",
    }),
    ...mapActions({
      GET_USER_DATA: "getUserData",
      GET_CURRENT_USER_PLAN: "getCurrentUserPlan",
    }),
  },
  computed: {
    showOnRequiresAuth() {
      return this.$route.meta.requiresAuth;
    },
    showTempSideNav() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    ...mapState({
      USER: "user",
    }),
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if(!user) return
      this.UPDATE_USER(user);
      this.GET_USER_DATA();
      this.GET_CURRENT_USER_PLAN();
    });
  },
};
</script>

<style>
.logo-font {
  font-family: "Audiowide", cursive;
}
</style>
