import { mapState, mapActions } from "vuex";
import xlsx from "json-as-xlsx";

export default {
  data: () => ({
    requiredRule: [(v) => !!v || "This field is required"],
    requiredRuleMulti: [(v) => !!v.length || "This field is required"],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    numberRules: [
      (v) => !!v || "Number is required",
      (v) => /[0-9]{11}/.test(v) || "Number must be 11 digits",
    ],
  }),
  methods: {
    ...mapActions({
      GET_BATCH_INFO: "getBatchInfo",
      GET_BATCH_RESULTS: "getBatchResults",
    }),
    openNewTab(route) {
      window.open(window.location.origin + "/#/" + route, "_blank");
    },
    async downloadBulkReports(bulkBatchId) {
      // Get all reports for this batch
      await this.GET_BATCH_INFO(bulkBatchId);
      await this.GET_BATCH_RESULTS(bulkBatchId);
      // Parse them into suitable format
      let data = [
        {
          sheet: "Reports only",
          columns: [
            { label: "Name", value: "studentName" },
            { label: "Report", value: "result" },
          ],
          content: this.BATCH_RESULTS_TO_DOWNLOAD.map((x) => ({
            studentName: x.reportInfo.studentName,
            result: x.result,
          })),
        },
        {
          sheet: "All data",
          columns: [
            { label: "Name", value: "studentName" },
            { label: "Gender", value: "gender" },
            { label: "Effort", value: "effort" },
            { label: "Tone", value: "tone" },
            { label: "Uniqueness", value: "unique" },
            { label: "Current Grade", value: "currGrade" },
            { label: "Target Grade", value: "targGrade" },
            { label: "Keywords", value: "keywords" },
            { label: "Report", value: "report" },
          ],
          content: this.BATCH_RESULTS_TO_DOWNLOAD.map((x) => ({
            studentName: x.reportInfo.studentName,
            gender: x.reportInfo.gender,
            effort: x.reportInfo.effort,
            tone: x.reportInfo.tone,
            unique: x.reportInfo.unique,
            currGrade: x.reportInfo.currGrade,
            targGrade: x.reportInfo.targGrade,
            keywords: x.reportInfo.keywords,
            report: x.result,
          })),
        },
      ];
      // download as Excel
      let settings = {
        fileName: this.BATCH_TO_DOWNLOAD.name.replace(/ /g, "_"), // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };
      xlsx(data, settings);
    },
    conv2Date(unix) {
      unix = Number(unix) * 1000;
      return (
        new Date(unix).toLocaleDateString() +
        " " +
        new Date(unix).toLocaleTimeString()
      );
    },
  },
  computed: {
    ...mapState({
      BATCH_TO_DOWNLOAD: "batchToDownload",
      BATCH_RESULTS_TO_DOWNLOAD: "batchResultsToDownload",
    }),
  },
};
