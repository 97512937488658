<template>
  <div>
    <v-navigation-drawer
      app
      :permanent="!temp"
      :temporary="temp"
      :fixed="temp"
      v-model="toggleTempSideNav"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-content>
            <v-img
              @click="$router.push('/templates')"
              style="cursor: pointer"
              alt="Edutopia Name"
              class="shrink ma-3"
              contain
              src="../../public/logo-text-blue-full.svg"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item two-line>
          <v-list-item-content>
            <div class="text-h6 primary--text">
              Hi {{ USER_DATA.fName }} 👋
              <div class="text-caption">
                <!-- truncate email if it's more than 30 chars -->
                {{ shortnedEmail }}
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="page in pages"
          :key="page.title"
          link
          @click="goToRoute(page.link)"
          :disabled="checkRoute(page.link)"
          :class="{ routerLinkStyle: checkRoute(page.link) }"
        >
          <!-- :class="{ 'blue lighten-4': checkRoute(page.link) }" -->
          <v-list-item-icon>
            <v-icon :color="checkRoute(page.link) ? 'primary' : 'black'">
              {{ checkRoute(page.link) ? page.iconFilled : page.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card outlined class="mx-6 mt-6 mb-3 rounded-t-xl">
        <v-card-title class="text-body-2 primary--text font-weight-bold">
          <v-icon color="primary" small left> mdi-cash-multiple </v-icon>
          Standard Credits
        </v-card-title>
        <v-card-text class="text-caption">
          <div v-if="creditsPerc !== -1">
            <v-progress-linear
              class="mb-3"
              striped
              :value="creditsPerc"
            ></v-progress-linear>
            {{ creditsPerc }}% of your standard credits used.
          </div>
          <div
            :class="USER_DATA.credits <= 50 ? 'red--text font-weight-bold' : ''"
          >
            <number
              :to="USER_DATA.credits"
              :duration="0.5"
              easing="Power1.easeOut"
              :format="formatNum"
            />
            remaining.
          </div>
          <v-btn
            v-if="USER_DATA.credits <= 50 && $route.path !== '/billing'"
            @click="$router.push('/billing')"
            class="mt-2 font-weight-bold"
            color="red"
            outlined
            rounded
            x-small
          >
            <span>Get more</span>
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card outlined color="primary" class="mx-6 mb-6 rounded-b-xl">
        <v-card-title class="text-body-2 white--text font-weight-bold">
          <v-icon color="white" small left> mdi-gift </v-icon>
          Bonus Credits
        </v-card-title>
        <v-card-text class="white--text text-caption">
          <number
            :to="USER_DATA.bonusCredits"
            :duration="0.5"
            easing="Power1.easeOut"
            :format="formatNum"
          />
          remaining.
          <br />
          <v-chip-group column>
            <v-chip @click="copyReferralCode" color="success" small label>
              <v-icon small left> {{ copyIcon }} </v-icon>
              <span class="font-weight-bold">Copy referral link</span>
            </v-chip>
            <v-chip
              v-if="$route.path !== '/billing'"
              @click="$router.push('/billing')"
              color="white"
              outlined
              small
              label
            >
              <v-icon small left> mdi-information-outline </v-icon>
              <span class="font-weight-bold">Learn more</span>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-list>
        <v-list-item @click="logout">
          <!-- :class="{ 'blue lighten-4': checkRoute(page.link) }" -->
          <v-list-item-icon>
            <v-icon>
              <!-- logout icon -->
              mdi-logout
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sign out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-bottom-navigation
      v-if="delayShow"
      color="primary"
      fixed
      :input-value="temp"
    >
      <v-btn @click="toggleTempSideNav = !toggleTempSideNav">
        <span>Menu</span>

        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-btn @click="$router.push('/templates')">
        <span>Templates</span>

        <v-icon>mdi-text-box-multiple-outline</v-icon>
      </v-btn>

      <v-btn @click="$router.push('/history')">
        <span>History</span>

        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
    
<script>
import { mapState } from "vuex";
import helperFuncs from "@/mixins/helperFuncs.js";
import { getAuth, signOut } from "firebase/auth";
const auth = getAuth();

export default {
  name: "SideNav",
  data: () => ({
    copyIcon: "mdi-content-copy",
    delayShow: false,
    toggleTempSideNav: false,
    pages: [
      {
        icon: "mdi-text-box-multiple-outline",
        iconFilled: "mdi-text-box-multiple",
        title: "Templates",
        link: "/templates",
      },
      {
        icon: "mdi-history",
        iconFilled: "mdi-history",
        title: "History",
        link: "/history",
      },
      {
        icon: "mdi-credit-card-outline",
        iconFilled: "mdi-credit-card",
        title: "Billing",
        link: "/billing",
      },
      // {
      //   icon: "mdi-cog-outline",
      //   iconFilled: "mdi-cog",
      //   title: "Settings",
      //   link: "/settings",
      // },
    ],
  }),
  mixins: [helperFuncs],
  props: {
    temp: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // When we don't delay show
    // the bottom navigation bar appears
    // on page load :( (I don't know why!)
    setTimeout(() => {
      this.delayShow = true;
    }, 500);
  },
  methods: {
    copyReferralCode() {
      // get fb uid
      // encode base64 it
      let encodedUid = btoa(this.USER.uid);
      navigator.clipboard.writeText(
        `${window.location.origin}/login/?_ref_edu=${encodedUid}`
      );
      this.copyIcon = "mdi-check-circle-outline";
      setTimeout(() => {
        this.copyIcon = "mdi-content-copy";
      }, 500);
    },
    formatNum(number) {
      // add comma if numbre is more than 4 digits
      if (number > 999) {
        return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return number.toFixed(0);
    },
    checkRoute(link) {
      return this.$route.path === link;
    },
    goToRoute(link) {
      if (this.$route.path !== link) {
        this.$router.push(link);
      }
    },
    logout() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    creditsPerc() {
      if (this.USER_PLAN.info.credits) {
        return (
          100 -
          Math.round(
            (this.USER_DATA.credits / this.USER_PLAN.info.credits) * 100
          )
        );
      }
      return -1;
    },
    shortnedEmail() {
      if (this.USER_DATA.email) {
        return this.USER_DATA.email.length > 30
          ? this.USER_DATA.email.substring(0, 30) + "..."
          : this.USER_DATA.email;
      }
    },
    ...mapState({
      USER: "user",
      USER_DATA: "userData",
      USER_PLAN: "userPlan",
    }),
  },
};
</script>

<style scoped>
.routerLinkStyle {
  font-weight: bold;
  color: #331877;
}
</style>